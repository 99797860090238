<template>
    <div class="uk-grid uk-grid-medium" data-uk-grid>
    <!-- panel -->
    <div class="uk-width-1-1">
        <div class="uk-card-default uk-card-small uk-card-hover">
            <div class="uk-card-header">
                <div class="uk-grid uk-grid-small">
                    <div class="uk-width-expand"> 
                        <div class="uk-inline uk-width-1-1">
                            <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: search"></span>
                            <input class="uk-input uk-width-1-1 search-fld" type="text" :placeholder="localizeFilter('Search')" v-model="val" @input="filter(val)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-card-body uk-margin-medium-bottom uk-padding-remove-top">
                <div class="form-container uk-overflow-auto">
                    <Table/>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import localizeFilter from '@/filter/localize.filter'
import {eventBus} from '@/main'
import Table from '@/views/Table'

export default {
    data: () => ({
        password: '',
        login: '',
        key: '',
        val: ''
    }),
    components:{
        Table
    },
    methods:{
        localizeFilter,
        filter(val){
            eventBus.$emit('gofilterDashboard', {
                    val: val
                })
        }
    }
}
</script>